import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
    flex1: {
        flex: 1,
    },
    container: { width: '100%' },
});

export default styles;
